<template>
  <infinite-loading :identifier="infiniteIdentifier" class="col-12" spinner="spiral" @infinite="infiniteHandler"/>
</template>

<script>
import { SET_IS_SEARCHING } from '@/modules/app/store/mutation-types'

export default {
  name: 'KurccInfiniteLoading',
  props: {
    items: {
      type: Array,
      required: true
    },
    actionName: {
      type: String,
      required: true
    },
    filter: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      infiniteIdentifier: 0,
      options: {
        page: 1,
        itemsPerPage: 10
      },
      debounceTimeout: null
    }
  },
  computed: {
    localItems: {
      get () {
        return this.items
      },
      set (v) {
        this.$emit('update:items', v)
      }
    },
    searchQuery () {
      return this.$store.state.search.query
    },
    isSearching: {
      get () {
        return this.$store.state.search.isSearching
      },
      set (v) {
        this.$store.commit(SET_IS_SEARCHING, v)
      }
    },
    filterDialogFilters () {
      return this.$store.state.filter
    }
  },
  watch: {
    searchQuery: function () {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.isSearching = true
        this.reloadInspections()
      }, 1000)
    },
    filterDialogFilters: 'reloadInspections'
  },
  methods: {
    getAll () {
      const {
        itemsPerPage: perPage,
        page: currentPage
      } = this.options

      return new Promise((resolve, reject) => {
        return this.$store.dispatch(this.actionName, {
          perPage,
          currentPage,
          withIncludes: true,
          searchQuery: this.searchQuery,
          orderBy: this.filterDialogFilters.orderBy,
          sortedBy: this.filterDialogFilters.sortedBy,
          additionalParams: this.combinedFilters()
        }).then((res) => {
          resolve(res.items)
          // if (this.currentPage > res.pagination.total_pages) {
          //   this.currentPage = 1
          // }
          // this.totalPages = res.pagination.total_pages
        }).catch(err => {
          reject(err)
        })
      })
    },
    infiniteHandler ($state) {
      this.getAll().then(data => {
        if (data.length) {
          this.options.page += 1
          this.localItems.push(...data)
          $state.loaded()
        } else {
          $state.complete()
        }
      }).catch(() => $state.error())
        .finally(() => {
          this.isSearching = false
        })
    },
    reloadInspections () {
      this.options.page = 1
      this.localItems = []
      this.changeInfiniteScrollIdentifier()
    },
    changeInfiniteScrollIdentifier () {
      this.infiniteIdentifier += 1
    },
    combinedFilters () {
      if (this.actionName !== 'getAllInspections') {
        return this.filter
      }

      const filters = this.filter ? this.$lodash.cloneDeep(this.filter) : []
      if (!this.$lodash.isNull(this.filterDialogFilters.assignmentStatus)) {
        filters.push(`is_assigned=${this.filterDialogFilters.assignmentStatus ? 1 : 0}`)
      }
      if (!this.$lodash.isNil(this.filterDialogFilters.categories)) {
        const categoryIds = this.$lodash.map(this.filterDialogFilters.categories, category => `"${category.id}"`)
        filters.push(`category_ids=[${categoryIds}]`)
      }
      return filters
    }
  }
}
</script>
